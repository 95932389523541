@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

input.largerCheckbox { 
  width: 18px !important; 
  height: 18px !important; 
  cursor: pointer;
} 

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

a {
  text-decoration: none !important;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif !important;
  scroll-behavior: smooth;
  background: #fcfcfc !important;
}


html {
  --Primary: #0338e7;
  --Secondary: #1073ff;
  --Ternary:#5179ff;
  --PrimaryGradient: linear-gradient(45deg, #0338e7, #1073ff);
  --FoxBlack: #000000;
  --White: #FFFFFF;
  --Black:#000000;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

h6, p, td{
white-space: pre-line !important;
}
  @media (max-width: 640px) {
     input[type="date"]:before {
        content: 'dd-mm-yyyy' !important;
    }

     input[type="date"]:focus:before {
        content: '' !important;
    }

     input[type="date"] {
        display: flex !important;
    }
}


#uploadBtn{
  background: transparent;
  border: 2px dashed rgb(199, 199, 199);
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 14px;
  color: #575757;
  display: flex;
  justify-content: center;
  align-items: center;
}
#uploadBtn:hover{
  cursor: pointer;
}


.uploadedFile{
  position: relative !important;
  padding: 8px !important;
}

 .uploadedFile .removeIcon{
  color: red ;
  position: absolute ;
  top: -7px ;
  right: -7px ;
  transition: 0.2s;
}

.uploadedFile .removeIcon:hover{
  cursor: pointer;
  transform: scale(1.19);

}

.uploadFile {
  width: max-content;
  height: auto;
  overflow: hidden;
  border: 2px dashed rgb(199, 199, 199);
  margin-top: 6px;
  padding: 6px 12px;
  margin: auto;
}

.uploadFile img {
  width: 60px;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
}

.uploadFile h6 {
  margin-bottom: 0px;
}


.uploadFile p {
  font-size: 14px;
  color: red;
  cursor: pointer;
  margin-left: 25px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}



/* ----------- Global Buttons & Err Msg Declaration ------------ */
.errMsg{
  color: red !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  margin: 3px 0px 0px 5px !important;
  text-align: start !important;
}

.InputLabel{
  color: #6f6f6f;
  font-weight: 500;
  margin: 0;
  z-index: 1000;
}

.loginBtn{
  color: #FFFFFF !important;
  background: #000000 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: none !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  position: relative !important;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1) !important;
  outline: none !important;
}

.primaryBtn, .mediaButton{
  color: var(--White) !important;
  padding: 6px 16px !important;
  background: linear-gradient(45deg, #0338e7, #1073ff) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: none !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  position: relative !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  outline: none !important;
}


.primaryBtn:hover{
  color: #021bfe !important;
  background: var(--White) !important;
  border: none !important;
  border-radius:6px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 500 !important;
}

.approveBtn{
  color: var(--White) !important;
  padding: 6px 16px !important;
  background: linear-gradient(45deg, #29d93a, #1acd2c) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: none !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  position: relative !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  outline: none !important;
}

.approveBtn:hover{
  color: #1acd2c !important;
  background: var(--White) !important;
  border: none !important;
  border-radius:6px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 500 !important;
}

.rejectBtn{
  color: var(--White) !important;
  padding: 6px 16px !important;
  background: linear-gradient(45deg, #fc2727, #F81C1C) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: none !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  outline: none !important;
}

.rejectBtn:hover{
  color: #F81C1C !important;
  background: var(--White) !important;
  border: none !important;
  border-radius:6px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 500 !important;
}

.secondaryBtn{
  color: var(--Primary) !important;
  background: transparent !important;
  border: 1px solid var(--Primary) !important;
  border-radius:6px !important;
  transition: 0.3s !important;
  padding: 5px 11px !important;
}

.secondaryBtn:hover{
  color: var(--Primary) !important;
  background: transparent !important;
  border: 1px solid var(--Primary) !important;
  border-radius:6px !important;
}

.status{
display: flex;
align-items: center;
justify-content: space-evenly;
}

.LogoutBtn{
  color: var(--White) !important;
  background: transparent !important;
  width: 100% !important;
  border: none !important;
  border-radius:6px !important;
  transition: 0.3s !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  transition: 0.3s !important;
}

.LogoutBtn:hover{
  color: var(--White) !important;
  background: transparent !important;
  width: 100% !important;
  border: none !important;
  border-radius:6px !important;
 transform: translateX(-10px) !important;
}

.pending{
  color: orange;
}

.approve{
  color:#21D333
}

.reject{
  color:red
}

.CancelBtn{
  color: #000000 !important;
  border: 1px solid #F7CE40 !important;
  background: transparent !important;
  border-radius: 6px !important;
  transition: 0.3s !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 5px 11px !important;
}
.spinner-border{
  color: var(--White) !important;
  margin-right: 6px;

}
.accordionNav{
  border: 1px solid var(--White) !important;
  color: var(--White) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  font-weight: 500 !important;
  background: transparent !important;
  font-size: 14px !important;
}

.accordionNavActive{
  border: 1px solid transparent !important;
  color: var(--Primary) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
  background: var(--White) !important;
  font-size: 14px !important;
}


.pagination{
  width: 100% !important;
}

.pagination .prev,
.pagination .prev:focus,
.pagination .next,
.pagination .next:focus{
  background: transparent !important;
  border: none !important;
  color: var(--FoxBlack) !important;
  transition: 0.3s !important;
}

.pagination .prev:hover{
  background: transparent;
  border: none;
  color: var(--FoxBlack);
  transform: translateX(-5px);
} 

.pagination .next:hover{
  background: transparent;
  border: none;
  color: var(--FoxBlack);
  transform: translateX(5px);
} 

.pagination .activePageButton{
  border: none !important;
  color: var(--FoxYellow) !important;
  background: var(--White) !important;
  transition: 0.1s !important;
  font-weight: 600;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.pagination .pageButton{
  border: none !important;
  color: var(--FoxBlack) !important;
  background: transparent !important;
  transition: 0.1s !important;
}

.pagination select.form-select{
  border: 1px solid #6f6f6f;
  transition: 0.3s;
}

.modal .modal-content {
  border: none !important;
  border-radius: 12px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.modal .modal-content .modal-header{
  background: var(--FoxYellow);
  padding: 10px 16px;
  font-weight: 600;
}

.modal .modal-content .modal-header h5{
  margin: 0px;
  font-weight: 600;
  color: var(--FoxBlack);
}

.modal label{
  font-size: 15px !important;
  color: #575757;
  font-weight: 600 !important;
  margin: 0px 0px 4px !important;
}

.modal .modal-footer{
  background: #ffffff !important;
  padding: 10px;
}

.profile h4{
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 0;
}

.profile .card{
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
  inset 0 0 3px rgba(0, 0, 0, 0.2) !important;
}

.profile .card div{
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.profile .card h6{
  margin: 0px;
}

.profile .card p{
  margin-left: 6px;
  margin-bottom: 0px;
}
/* ----------- LOGIN ------------ */

.login{
  height: 100vh;
  width: 100vw;
  background: url('./Assets//LoginBg.svg') center center no-repeat;
  background-size:cover;
}

.login input.form-control:focus,
.Register input.form-control:focus{
  border: 1px solid #6f6f6f;
  outline: none;
  box-shadow: none;
}

.login .card{
  border: none;
  border-radius: 12px;
  padding: 20px 20px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}

.login h2{
  font-weight: 600;
  margin-bottom: 0px;
  text-align: center;
}

.login p{
  font-weight: 500;
  color: #575757;
}

 label.form-label{
 color: #575757 !important;
 font-size: 14px !important;
 margin-bottom:4px !important;
 font-weight: 600 !important;
}

label.form-label span{
  color: red !important;
}


/* ------ Forgot Pass ------ */
.ForgotPass{
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ForgotPass .card{
    border: none;
  border-radius:12px;
  padding: 0px 20px 25px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.ForgotPass .card h3{
  font-weight: 600;
  text-align: center;
  margin-bottom: 4px;
}
.ForgotPass .card h5{
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}
.ForgotPass .card p{
  font-size: 14px;
  color: #575757;
  text-align: center;
  margin-bottom: 8px;
}


/* -------------------- Skeleton Loader CSS ------------------------- */
  .loader {
    background-color: #ededed;
    height: 35px;
    border-radius: 2px;
    width: 100%;
  }

  .tr {
    border-bottom: 1px solid #ddd;
  }

  .loader .td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  td .loader {
    background-color: #ededed;
    background: linear-gradient(100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, .5) 50%,
    rgba(255, 255, 255, 0) 60%) #ededed;
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
  }

  @keyframes loading {
    to {
        background-position-x: -40%;
    }
  }


nav {
  position: fixed;
  left: 42vh;
  right: 0vh;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  padding: 4px 20px;
  z-index: 1000;
}

nav h5 {
  color: black;
  font-weight: 600;
  margin-bottom: 0px;
}

 nav ul {
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

nav ul li {
  letter-spacing: 1px;
  font-weight: 400;
  padding: 2px 10px;
  transition: 0.2s;
  display: inline-block;
  color: var(--yellow-brand-color);
}

nav ul li:hover {
  transform: translateY(-3px);
  color: white;
}

@media (max-width: 992px) {
  .lg-nav {
    display: none;
  }

  .mobile-nav {
    position: fixed;
    --gap: 2rem;
    border-radius: 0px;
    inset: 0 0 0 20%;
    z-index: 1000;
    flex-direction: column;
    padding: 20% 2% 20% 0px;
    transform: translateX(100%);
    transition: 0.3s;
    list-style: none;
    margin: 0;
    background: var(--PrimaryGradient);
  }

  .mobile-nav li {
    margin-bottom: 15px;
    color: yellow;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .mobile-nav a.nav-link{
    border: 1px solid var(--FoxBlack);
    color: var(--FoxBlack);
    display: flex;
    align-items: center;
    border-radius: 12px;
    margin-bottom: 15px;
    font-weight: 500;
 }
 
  .mobile-nav a.nav-link.active{
   background: var(--FoxBlack);
   border: 1px solid transparent;
   color: #ffffff;
   font-weight: 500;
 }

  .mobile-nav[data-visible="true"] {
    transform: translateX(0%);
  }

  .mobile-nav-toggle {
    margin:5px 0px 5px 0px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 30px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    /* border: 3px solid #fff; */
  }
  .mobile-nav-toggle .menu-btn__burger {
    width: 35px;
    height: 3px;
    background: #F7CE40;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    z-index: 9999;
  }
  .mobile-nav-toggle .menu-btn__burger::before,
  .mobile-nav-toggle .menu-btn__burger::after {
    content: "";
    position: absolute;
    width: 36px;
    height: 3px;
    background: #F7CE40;
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }

  .mobile-nav-toggle .menu-btn__burger::before {
    transform: translateY(-10px);
  }

  .mobile-nav-toggle .menu-btn__burger::after {
    transform: translateY(10px);
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }

}

/* ------ SideBar-lg CSS for Large or Desktop Devices ------ */
@media (min-width: 992px) {
  .lg-nav {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .PopupData div{
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    margin-top: 5px;
  }

  .PopupData div p{
    margin-bottom: 0px;
  }

  .mobile-nav {
    display: none;
  }

  .mobile-nav-toggle {
    display: none;
  }

  .sidenav-lg::-webkit-scrollbar {
    width: 5px;
  }
  .sidenav-lg::-webkit-scrollbar-track {
    background: var(--White);
  }
  .sidenav-lg::-webkit-scrollbar-thumb {
    background-color: #93c0ff;
    border-radius: 6px;
  }

  .sidenav-lg {
    height: 100vh;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    /* background: #edf4ff; */
    background: var(--PrimaryGradient);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding-right: 20px;
    /* overflow-y: scroll; */
    width: 42vh;
    scroll-behavior: smooth;
  }

  .sidenav-lg hr{
    border-width: 2px;
    color: #ffffff;
    width: 40%;
    margin: auto;
    opacity: 1;
  }

  .sidenav-lg-parent {
    position: relative;
  }
 
  .outletDiv {
    position: absolute;
    left: 45vh;
    right: 2vh;
    top: 0vh;
  }
}

@media (max-width: 992px) {
  .outletDiv {
    position: absolute;
    left: 1vh;
    right: 1vh;
    top: 0vh;
  }

  nav{
    left: 0;
    right: 0;
  }
}

/* --- SideBar Accordion CSS for all sizes devices ----*/
.accordion{
  background-color: transparent !important;
}

.accordion .accordion-item{
  border: #000000;
  background: transparent !important;
}

.accordion .accrodion-body {
  background: var(--White) !important;
  box-shadow: none !important;
  border: none !important;
}

.accordion-item .accordion-header {
  background-color: transparent !important;
  border: none !important;
  padding: 0px;
  box-shadow: none;
  color: var(--White) !important;
  border: none !important;
}

.accordion .activeMenu .accordion-button {
  color: var(--Primary) !important;
  background: var(--White) !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  font-weight: 600 !important;
}

.accordion .dropDown{
  border-radius: 0px 0px 12px 0px !important;
}

.accordion .activeMenu.dropDown{
 background: var(--White) !important;
 border-radius: 0px 12px 12px 0px !important;

}

.accordion .activeMenu.dropDown .accordion-button{
  border-radius: 0px 12px 12px 0px !important;
}

.accordion .accordion-button {
  background: transparent !important;
  color: var(--White) !important;
  border: none !important;
  font-weight: 400 !important;
  border-radius: 0px 12px 12px 0px !important;
  box-shadow: none !important;
  padding: 14px 20px !important;
  font-size: 16px !important;
}

.accordion .accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  /* color: #FFFFFF !important; */
  /* background: linear-gradient(145deg, #1267cf, #073c7c) !important;
  color: #ffffff !important; */
}
/*
.accordion .accordion-button:not(.collapsed){
    background: linear-gradient(145deg, #1267cf, #073c7c) !important;
    color: #ffffff !important;
    font-weight: 500 !important;
} */

.accordion-item .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%0ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
.accordion-item .accordion-button:focus:after {
  box-shadow: none;
  outline: #000000 !important;
  border: none !important;
  /* background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%0ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important; */
}
.accordion .dropDown .accordion-button:after{ 
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion .activeMenu.dropDown .accordion-button:after{ 
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23073c7c'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

/* .accordion .dropDown .accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23073c7c'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion .dropDown .accordion-button.collapsed:after {
  box-shadow: none;
  outline: #000000 !important;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
} */

.accordion  label:nth-child(1){
  color: rgb(222, 219, 219) !important;
  padding: 1px 0px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin: 0px 0px 0px 0px !important;

}

.accordion  label{
  color: rgb(222, 219, 219) !important;
  padding: 1px 20px !important;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0px 0px 0px;

}

.accordionNav{
  border: 1px solid var(--Primary) !important;
  color: var(--Primary) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  font-weight: 500 !important;
  background: transparent !important;
}

.accordionNav:hover{
  color: var(--White) !important;
  background: var(--Primary) !important;
}

.accordionNavActive{
  border: 1px solid transparent !important;
  color: var(--White) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important; 
  font-weight: 500 !important;
  background: var(--Primary) !important;
}

/* -------------------- DashCards ----------------- */
.Dashboard h2{
  font-weight: 600;
}

.Dashboard .card {
  position: relative;
  border: none;
  border-radius: 12px;
  overflow: hidden;
  margin: 0px 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  
 
}
.Dashboard .card:hover{
  cursor: pointer;
}

.Dashboard .card:hover .card-image{
 transform: scale(1.9);
 
}

.Dashboard .card h2{
  font-weight: 700;
}

.Dashboard .card h5{
  color: var(--Black);
  font-weight: 600;
}

.Dashboard .card .card-image {
  transition: 0.2s ease-in-out;
  position: absolute;
  bottom: -5px;
  right: -5px;
  width: 60px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat; 
}



  .uploadFile{
    border: 2px dashed rgb(199, 199, 199);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    margin-top: 6px;
  }

  .uploadFile img{
    width: 40px;
    margin-right: 10px;
  }
  .uploadFile h6{
    margin-bottom: 0px;
  }
  .uploadFile svg{
    color: red;
    margin-left: auto;
    cursor: pointer;
  }

  .ViewDept .card{
    border: 1px solid rgb(113, 203, 255);
    border-radius: 12px;
    padding: 20px;
    color: #000000;
    background: #FFFFFF;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    display: flex;
  }
  .ViewDept .card h6{
    margin-bottom: 8px;
  }
  .ViewDept .card p{
    margin-bottom: 6px;
  }

/*----------------------- Manage Users CSS ----------------------------*/
 .outer-wrapper .icon{
  transition: 0.3s;
  cursor: pointer;
}
.outer-wrapper .icon:hover{
  transform: translateY(-4px) scale(1.2);
}



/* ------------ Adding Artificial margin to every Oulets component's perent Div  ------------------- */ 

.outletPadding{
  margin-top: 70px !important;
}


/*  ---------------- Fixed header Responsive table CSS ---------------*/

.outer-wrapper {
  margin: 0px ;
  padding: 0px ;
  border-radius: 0px  ;
  background: #ffffff ;
  max-width: 100% ;
  max-height: fit-content ;
}

.outer-wrapper .editIcon {
  transition: 0.3s ;
  color: #0065e0 ;
  width: 22px ;
  margin: auto;
}

.outer-wrapper .deleteIcon {
  transition: 0.3s ;
  color: #d32700 ;
  width: 22px ;
}

.outer-wrapper .editIcon:hover,
.outer-wrapper .deleteIcon:hover {
  transform: translateY(-4px) scale(1.2) ;
  cursor: pointer ;
}

.table-wrapper {
  overflow-x: auto;
  height: 70vh;
  margin-top: 5px;
  padding: 0px;
}


.table-wrapper::-webkit-scrollbar {
  width: 6px; 
  height: 6px;
}


.table-wrapper::-webkit-scrollbar-track {
  background: #cdcdcd; 
  border-radius: 6px;
}

.table-wrapper::-webkit-scrollbar-track:hover {
 cursor: pointer;
}


.table-wrapper::-webkit-scrollbar-thumb {
  background: var(--Ternary);
  border-radius: 4px;
}


/* .table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.outer-wrapper table {
  width: 76vw;
  min-width: max-content !important;
  border-collapse: separate;
  border-spacing: 0px;
}

.outer-wrapper table th {
  z-index: 1 !important;
  position: sticky;
  top: 0px;
  background: hsl(212, 100%, 95%);
  color: var(--Black);
  text-align: left;
  font-weight: 600; 
  font-size: 15px;
  outline: 0.7px solid transparent;
  border: 1.5px solid transparent;
  border: 0.5px solid rgba(137, 137, 137, 0.1);
}


.error{
  color: red;
}

.outer-wrapper table th,
.outer-wrapper table td {
  padding: 10px;
}

.outer-wrapper table td {
  text-align: left;
  font-size: 13px;
  border: 0.4px solid rgba(255, 199, 165, 0.1);
  width: max-content;
}

table tfoot {
  position: sticky;
  inset-block-end: 0;
  top: 0px;
}


/* ----------- Manage consumers -------------------*/
.modal-content .modal-body .nav{
  width:max-content ;
  border: none;
  border-radius: 8px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  box-shadow: rgb(204, 219, 232) 2px 2px 6px 2px inset,
  rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}
.modal-content .modal-body .nav .nav-link{
  border-radius: 8px;
  color: var(--Primary);
  padding: 6px 12px;
}
.modal-content .modal-body .nav .nav-link.active{
  color: var(--White);
  background: var(--PrimaryGradient);
  padding: 6px 12px;
  border: none;
  border-radius: 8px;
}


/*-------------------- Messenger CSS --------------------------------*/

  .MessengerLayout{
    display: flex;
    position: relative;
    height: 93vh;
    top: 0;
    margin-top: 49px;
  }

  .MessengerLayout .left{
    position: fixed;
    top: 7vh;
    left: 43vh;
    bottom: 0;
    min-width: 45vh;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
  .MessengerLayout .left .ContactList{
    overflow-y: scroll !important;
    max-height: 78vh !important;
    scroll-behavior: smooth;
  }

  .MessengerLayout .left .ContactList::-webkit-scrollbar,
  .Conversation .chats::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  .MessengerLayout .left .ContactList::-webkit-scrollbar-track,
  .Conversation .chats::-webkit-scrollbar-track {
    background: var(--White);
  }

  .MessengerLayout .left .ContactList::-webkit-scrollbar-thumb,
  .Conversation .chats::-webkit-scrollbar-thumb {
    background-color: #93c0ff !important;
    border-radius: 6px;
  }



  .MessengerLayout .left .ContactList .contactCard{
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    cursor: pointer;
  }

  .MessengerLayout .left .ContactList .contactCardActive{
    background: #e1e1e1;
  }

  .MessengerLayout .left .ContactList .contactCard:hover{
    background: #e1e1e1;
  }

  .MessengerLayout .left .ContactList .contactCard h6{
    margin-bottom: 0px;
  }

  .MessengerLayout .left .ContactList .contactCard .lastMsgDate{
    margin-bottom: 0px;
    color: #575757;
    font-size: 12px;
    font-weight: 500;
  }

  .MessengerLayout .left .ContactList .contactCard .lastMsg{
    margin-bottom: 0px;
    color: #575757;
    font-size: 10px;
    font-weight: 500;
  }


  .MessengerLayout .right{
    position: absolute;
    top: 0vh;
    left: 45vh;
    bottom: 0;
    right: 0vh;
  }


  .ManageConsumer .nav{
    width:max-content ;
    border: none;
    border-radius: 8px;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    box-shadow: rgb(204, 219, 232) 2px 2px 6px 2px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  }
  .ManageConsumer .nav .nav-link{
    border-radius: 8px;
    color: var(--Primary);
    padding: 6px 12px;
  }
  .ManageConsumer .nav .nav-link.active{
    color: var(--White);
    background: var(--PrimaryGradient);
    padding: 6px 12px;
    border: none;
    border-radius: 8px;
  }


  /* ---------------------------- Send Multiple Message ----------------------------- */
  
    .SendMultipleMsg{
      border: 1px solid rgb(113, 203, 255);
      border-radius: 12px;
      padding: 20px;
      color: #000000;
      background: #FFFFFF;
      position: relative;
      scroll-margin-top: 5px;
    }

    .SendMultipleMsg .cancel{
      position: absolute;
      color: red;
      right: 4vh;
      top: 2vh;
      cursor: pointer;
    }

    .SendMultipleMsg h5{
      font-weight: 600;
    }

    .SendMultipleMsg label{
      font-size: 14px;
      color: #6c6c6c;
    }
    .SendMultipleMsg .form-check-input{
      border: 1px solid #6c6c6c;
    }
    .SendMultipleMsg .form-check-input:focus{
      box-shadow: none !important;
    }


  /*------------------------------- Onboard CSS -------------------------------------*/
   /* ------ Form Inputs ID's ------- */
    #officialName,
    #officialEmail,
    #officialContact,
    #fbEmail,
    #fbPass,
    #bankStatement,
    #bizLicense,
    #certOfIncorp,
    #gst,
    #pan,
    #adhar,
    #utilityBill,
    #metaAccountName,
    #bizName,
    #country,
    #bizWebsite,
    #wpProfileImg,
    #whatsappNo,
    #wpBizAccountName,
    #wpProfileDisplayName,
    #bizCategory,
    #wpWebsite,
    #bizDescription
    {
      scroll-margin-top: 70px !important;
    }
  /* .Onboard{
    height: 100vh;
    width: 100vw;
  } */
  .Onboard .card{
    border: none;
    border-radius: 12px;
    padding: 20px 30px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  }

  .Onboard .card h2{
    font-weight: 700;
    text-align: center;
    /* color: var(--Primary); */
    margin-bottom: 16px;
  }

  .Onboard .card h6{
    text-align: center;
    background:  linear-gradient(45deg, #0338e7, #1073ff);
    padding: 8px 12px;
    color: var(--White);
    margin-bottom: 12px;
  }

  @media(min-width:768px){
   .Onboard360{
    height: 100vh;
    width: 100vw;
  }
  .Onboard360 .imagebox {
    position: absolute;
    left: 56%;
    text-align:center;
    } 
    .Onboard360 .heading{
      width: 85%;
    }
    .Onboard360 .content{
      background: url('./Assets/OnboardingBg.svg') center center no-repeat;
      background-size: cover;
      clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
    }
}

@media(max-width:768px){

  .Onboard360 .content{
    background: url('./Assets/MobileOnboardingBg.svg') center center no-repeat;
    background-size: cover;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  }
  .Onboard360 .imagebox {
    text-align:center;
    } 
    .Onboard360 .heading{
      width: 100%;
    }
}



  .Onboard360 .content h5{
    color: var(--White);
  }

  .Onboard360 .imagebox h2{
    font-weight: 700;
  }
  
  .Onboard360 .card{
    border: none;
    border-radius: 12px;
    padding: 10px 0px 15px 15px;
    background: transparent;
    color: var(--White);
  }
  .Onboard360 .card  ul{
    margin: 0px !important;
    padding-left: 60px;
  }
  .Onboard360 .card  ul li{
    font-size: 14px;
    margin-bottom: 4px;
  }

  .ThankYou{
    height: 100vh;
    width: 100vw;
  }
  .ThankYou h2{
    font-weight: 600;
  }

  
  .msgCount{
    background-color: #1acd2c;
    color: white;
    padding: 0px 6px;
    border-radius: 50%;
    font-size: smaller;
  }

  .msgTime{
    color: green;
    font-size: smaller;
  }

  label.form-label .optionaltext{
    color: silver !important;
    font-size: smaller;
  }

  .noteMessage{
    font-size: smaller;
    color: gray;
  }

  .formatType{
    font-size: smaller;
    color: #0065e0;
  }

  .msgImage{
    max-width: 100%;
    height: auto;
  }

  .msgButton{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #398040 !important;
    padding: 5px 10px;
    font-weight: 600;
    border-top: 1px solid;
    border-color: rgb(185, 185, 185);
  }

  .msgButton a {
    color: inherit; /* Inherits the color from the parent */
    text-decoration: none; /* Removes underline */
    display: flex; /* Aligns icon and text */
    align-items: center;
  }

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
